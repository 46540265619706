<template>
  <div
    class="relative flex flex-col min-w-0 break-words mx-auto mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-green-900 text-white']"
    style="max-width: 1680px;"
  >
    <!-- header -->
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap justify-between items-center">
        <div class="relative z-10 mr-4 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            @click="table = 'nodes'"
            :class="[table === 'nodes' ? 'bg-red-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-200']"
            class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium focus:outline-none"
          >
            Nodes
          </button>
          <button
            type="button"
            @click="table = 'rc'"
            :class="[table === 'rc' ? 'bg-red-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-200']"
            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium focus:outline-none"
          >
            Resource Credits
          </button>
        </div>

        <div v-if="table === 'nodes'" class="relative z-10 mr-4 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            @click="showNodes = 'HIVE'"
            :class="[showNodes === 'HIVE' ? 'bg-red-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-200']"
            class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium focus:outline-none"
          >
            Hive
          </button>
          <button
            type="button"
            @click="showNodes = 'HE'"
            :class="[showNodes === 'HE' ? 'bg-red-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-200']"
            class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium focus:outline-none"
          >
            Hive Engine
          </button>
          <button
            type="button"
            @click="showNodes = 'HEH'"
            :class="[showNodes === 'HEH' ? 'bg-red-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-200']"
            class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium focus:outline-none"
          >
            HE History
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table v-if="table === 'nodes'" class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap w-1/5 font-semibold text-left"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              <span class="ml-3"> Node </span>
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap w-1/5 font-semibold text-center"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              Version
            </th>

            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap w-1/5 font-semibold text-center"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              Last update
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap w-1/5 font-semibold text-center"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              Score
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap w-1/5 font-semibold text-center"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              Tests
            </th>
          </tr>
        </thead>

        <!-- Hive Nodes -->
        <tbody v-if="showNodes === 'HIVE'">
          <tr v-for="node in hiveNodesSorted" :key="node.name">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4 text-left flex items-center">
              <span class="font-bold ml-3" :class="[color === 'light' ? 'text-gray-700' : 'text-white']">
                {{ node.name }}
                <small class="block text-gray-600 font-normal text-xs">{{ node.endpoint }}</small>
              </span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              {{ node.version }}
            </td>

            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              {{ minutesAgo(node.updated_at) }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
              <div class="flex w-4/5 items-center m-auto">
                <span class="mr-2">{{ node.score }}%</span>
                <div class="relative w-full hidden sm:block">
                  <div
                    class="overflow-hidden h-2 text-sm flex rounded"
                    :class="[
                      { 'bg-green-200': node.score === 100 },
                      { 'bg-yellow-200': node.score > 90 },
                      { 'bg-orange-200': node.score >= 80 },
                      { 'bg-red-200': node.score < 80 },
                    ]"
                  >
                    <div
                      :style="`width: ${node.score}%;`"
                      class="shadow-none flex flex-col whitespace-nowrap text-white justify-center"
                      :class="[
                        { 'bg-green-500': node.score === 100 },
                        { 'bg-yellow-500': node.score > 90 },
                        { 'bg-orange-500': node.score >= 80 },
                        { 'bg-red-500': node.score < 80 },
                      ]"
                    ></div>
                  </div>
                </div>
              </div>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              <a @click="openNodeModal(node)" class="cursor-pointer">
                <i
                  class="fas fa-circle mr-2"
                  :class="[
                    { 'text-green-500': node.score === 100 },
                    { 'text-yellow-500': node.score > 90 },
                    { 'text-orange-500': node.score >= 80 },
                    { 'text-red-500': node.score < 80 },
                  ]"
                >
                </i>
                {{ node.success }} / {{ node.success + node.fail }}
                <i class="fas fa-search text-lg text-default ml-3"></i>
              </a>
            </td>
          </tr>
        </tbody>

        <!-- Hive Engine Nodes -->
        <tbody v-if="showNodes === 'HE'">
          <tr v-for="node in heNodesSorted" :key="node.name">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4 text-left flex items-center">
              <span class="font-bold ml-3" :class="[color === 'light' ? 'text-gray-700' : 'text-white']">
                {{ node.name }}
                <small class="block text-gray-600 font-normal text-xs">{{ node.endpoint }}</small>
              </span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              {{ node.version }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              {{ minutesAgo(node.updated_at) }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
              <div class="flex w-4/5 items-center m-auto">
                <span class="mr-2">{{ node.score }}%</span>
                <div class="relative w-full hidden sm:block">
                  <div
                    class="overflow-hidden h-2 text-sm flex rounded"
                    :class="[
                      { 'bg-green-200': node.score === 100 },
                      { 'bg-yellow-200': node.score > 90 },
                      { 'bg-orange-200': node.score >= 80 },
                      { 'bg-red-200': node.score < 80 },
                    ]"
                  >
                    <div
                      :style="`width: ${node.score}%;`"
                      class="shadow-none flex flex-col whitespace-nowrap text-white justify-center"
                      :class="[
                        { 'bg-green-500': node.score === 100 },
                        { 'bg-yellow-500': node.score > 90 },
                        { 'bg-orange-500': node.score >= 80 },
                        { 'bg-red-500': node.score < 80 },
                      ]"
                    ></div>
                  </div>
                </div>
              </div>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              <a @click="openNodeModal(node)" class="cursor-pointer">
                <i
                  class="fas fa-circle mr-2"
                  :class="[
                    { 'text-green-500': node.score === 100 },
                    { 'text-yellow-500': node.score > 90 },
                    { 'text-orange-500': node.score >= 80 },
                    { 'text-red-500': node.score < 80 },
                  ]"
                >
                </i>
                {{ node.success }} / {{ node.success + node.fail }}
                <i class="fas fa-search text-lg text-default ml-3"></i>
              </a>
            </td>
          </tr>
        </tbody>

        <!-- Hive Engine History -->
        <tbody v-if="showNodes === 'HEH'">
          <tr v-for="node in hehNodesSorted" :key="node.name">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4 text-left flex items-center">
              <span class="font-bold ml-3" :class="[color === 'light' ? 'text-gray-700' : 'text-white']">
                {{ node.name }}
                <small class="block text-gray-600 font-normal text-xs">{{ node.endpoint }}</small>
              </span>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              {{ node.version }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              {{ minutesAgo(node.updated_at) }}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-4">
              <div class="flex w-4/5 items-center m-auto">
                <span class="mr-2">{{ node.score }}%</span>
                <div class="relative w-full hidden sm:block">
                  <div
                    class="overflow-hidden h-2 text-sm flex rounded"
                    :class="[
                      { 'bg-green-200': node.score === 100 },
                      { 'bg-yellow-200': node.score > 90 },
                      { 'bg-orange-200': node.score >= 80 },
                      { 'bg-red-200': node.score < 80 },
                    ]"
                  >
                    <div
                      :style="`width: ${node.score}%;`"
                      class="shadow-none flex flex-col whitespace-nowrap text-white justify-center"
                      :class="[
                        { 'bg-green-500': node.score === 100 },
                        { 'bg-yellow-500': node.score > 90 },
                        { 'bg-orange-500': node.score >= 80 },
                        { 'bg-red-500': node.score < 80 },
                      ]"
                    ></div>
                  </div>
                </div>
              </div>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap p-4">
              <a @click="openNodeModal(node)" class="cursor-pointer">
                <i
                  class="fas fa-circle mr-2"
                  :class="[
                    { 'text-green-500': node.score === 100 },
                    { 'text-yellow-500': node.score > 90 },
                    { 'text-orange-500': node.score >= 80 },
                    { 'text-red-500': node.score < 80 },
                  ]"
                >
                </i>
                {{ node.success }} / {{ node.success + node.fail }}
                <i class="fas fa-search text-lg text-default ml-3"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Resource Credits -->
      <table v-else-if="table === 'rc'" class="items-center w-full border-collapse">
        <thead>
          <tr>
            <th
              class="w-1/4 pl-24 align-middle border border-solid py-3 text-left text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              Transaction type
            </th>

            <th
              class="w-1/4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              <div class="flex items-center justify-end">
                RC cost
                <i class="fas fa-info-circle text-sm text-gray-500 ml-2" :content="'Daily average costs provided by API nodes.'" v-tippy="{ arrow: true }"></i>
              </div>
            </th>

            <th
              class="w-1/4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              HP equiv.
            </th>

            <th
              class="w-1/4 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center"
              :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
            >
              Last Update
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-if="rcCostsSorted.length">
            <tr v-for="record in rcCostsSorted" :key="record.operation">
              <td class="text-left pl-24 border-t-0 align-middle border-l-0 border-r-0 text-sm font-bold whitespace-no-wrap py-3">
                {{ splitAndCapitalize(record.operation.slice(0, record.operation.length - 9)) }}
              </td>

              <td class="flex justify-end items-center px-6 border-t-0 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap py-3">
                {{ (record.rc_needed / 10 ** 9).toFixed(3) }}b
                <i
                  class="far fa-question-circle text-xs text-gray-600 ml-1"
                  :content="numberInUsFormat(record.rc_needed) || 'No details'"
                  v-tippy="{ arrow: true }"
                ></i>
              </td>

              <td class="text-right items-center px-6 border-t-0 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap py-3">
                ~{{ record.hp_needed && record.hp_needed.toFixed(3) }}
              </td>

              <td class="border-t-0 align-middle border-l-0 border-r-0 text-center text-sm whitespace-no-wrap py-3">
                {{ minutesAgo(rcCosts.timestamp) }}
              </td>
            </tr>
            <tr>
              <td class="border-t-0 align-middle border-l-0 border-r-0 text-center text-base whitespace-no-wrap py-3" colspan="6">
                Check out more stats on
                <a href="https://beta.hivehub.dev/stats?metric=rc&timeframe=daily" target="_blank" class="text-red-500 font-bold">hivehub.dev</a>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td class="border-t-0 align-middle border-l-0 border-r-0 text-center text-base text-gray-600 whitespace-no-wrap py-5" colspan="6">
              Loading ...
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Nodes Modal -->
    <div v-if="showNodeModal" class="overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center sm:flex max-h-screen">
      <div class="relative w-auto mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 sm:rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
            <h3 class="text-3xl font-semibold">
              {{ selectedNode.name }}
            </h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="closeNodeModal()"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative overflow-x-auto flex-auto" style="max-height: 80vh">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left"
                    :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
                  >
                    Name
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left"
                    :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
                  >
                    Method
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left"
                    :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
                  >
                    Type
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left"
                    :class="color === 'light' ? 'bg-gray-100 text-gray-600 border-gray-200' : 'bg-green-800 text-green-300 border-green-700'"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="test in selectedNode.tests" :key="test.name">
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap py-2">
                    {{ test.name }}
                    <i class="far fa-question-circle text-lg text-gray-600 ml-2" :content="test.description || 'No details'" v-tippy="{ arrow: true }"></i>
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap py-2">
                    <span
                      class="text-xs font-semibold inline-block py-1 px-2 rounded uppercase last:mr-0 mr-1"
                      :class="[
                        { 'text-indigo-600 bg-indigo-200': test.type === 'fetch' },
                        { 'text-purple-600 bg-purple-200': test.type === 'cast' },
                        {
                          'text-gray-600 bg-gray-200': !['fetch', 'cast'].includes(test.type),
                        },
                      ]"
                    >
                      {{ test.type }}
                    </span>
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap py-2">
                    {{ test.method }}
                  </td>
                  <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap py-2">
                    <i class="fas fa-circle mr-2" :class="test.success ? 'text-green-400' : 'text-red-400'"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b">
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="closeNodeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showNodeModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    color: {
      default: 'light',
      validator: function(value) {
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      hiveNodes: [],
      heNodes: [],
      hehNodes: [],
      selectedNode: null,
      rcModalOperation: null,
      showNodeModal: false,
      showRcModal: false,
      showNodes: 'HIVE',
      table: 'nodes',
      rcCosts: {},
      pastTime: 0,
    };
  },
  computed: {
    hiveNodesSorted: function() {
      return [...this.hiveNodes].sort((a, b) => b.score - a.score);
    },
    heNodesSorted: function() {
      return [...this.heNodes].sort((a, b) => b.score - a.score);
    },
    hehNodesSorted: function() {
      return [...this.hehNodes].sort((a, b) => b.score - a.score);
    },
    rcCostsSorted: function() {
      const commonOp = [
        'vote_operation',
        'comment_operation',
        'custom_json_operation',
        'transfer_operation',
        'limit_order_create_operation',
        'claim_account_operation',
      ];
      const topOp = this.rcCosts.costs.filter(op => commonOp.includes(op.operation));
      const bottomOp = this.rcCosts.costs.filter(op => !commonOp.includes(op.operation)).filter(op => op.operation !== 'multiop');
      return topOp
        .sort((a, b) => {
          const aIdx = commonOp.indexOf(a.operation);
          const bIdx = commonOp.indexOf(b.operation);
          return aIdx - bIdx;
        })
        .concat(bottomOp.sort((a, b) => b.rc_needed - a.rc_needed));
    },
  },
  methods: {
    minutesAgo: function(date) {
      const now = Date.now();
      const timestamp = new Date(date).getTime();

      return now - timestamp < 0 ? 'now' : `${parseInt((now - timestamp) / 60000)} mins ago`;
    },
    splitAndCapitalize: function(snakeCaseText) {
      let words = snakeCaseText.split('_');
      return words.map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    },
    numberInUsFormat(number) {
      if (!number) return number;

      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getTimePassed(lastCycleTime, firstCycleTime) {
      const timePassed = (new Date(lastCycleTime).getTime() - new Date(firstCycleTime).getTime()) / 60000;
      return timePassed < 60 ? `${parseInt(timePassed)}m` : `${parseInt(timePassed / 60)}h`;
    },
    openNodeModal: async function(score) {
      const endpointPrefix = this.showNodes !== 'HIVE' ? (this.showNodes == 'HE' ? '/he' : '/heh') : '';
      const node = await axios.get(`${endpointPrefix}/nodes/${score.name}`);
      this.selectedNode = node.data;
      this.showNodeModal = true;
    },
    closeNodeModal: function() {
      this.selectedNode = null;
      this.showNodeModal = false;
    },
  },
  async mounted() {
    this.hiveNodes = await axios.get('/nodes').then(response => response.data);
    console.log(this.hiveNodes);

    this.heNodes = await axios.get('/he/nodes').then(response => response.data);
    console.log(this.heNodes);

    this.hehNodes = await axios.get('/heh/nodes').then(response => response.data);
    console.log(this.hehNodes);

    this.rcCosts = await axios.get('/rc/costs').then(response => response.data);
    console.log(this.rcCosts);
  },
};
</script>
